import React from "react";
import { Helmet } from "react-helmet";
import { FaCheck } from 'react-icons/fa';

import HeaderPicture from '../assets/img/app_bg.jpg';

import TransparentNavLayout from "../layouts/TransparentNavLayout";

export default function About() {
  return (
    <TransparentNavLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/about" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - À propos" />
        <meta property="og:description" content="Notre métier : Vous accompagnez dans le développement de vos projets digitaux." />
        <meta property="og:image" content={HeaderPicture} />
        <meta name="keywords" content="hivewapp, accompagnement, équipes, ambitions,
        consulting, conseils, projet, usages de demain, spécifications, mvp, développement informatique, méthodologie, landing page, outils technologiques,
        lean startup, architecture techniques, startup studio, la réunion, 974" />
        <meta name="description" content="Notre métier : Vous accompagnez dans le développement de vos projets digitaux." />
        <title>Hivewapp - À propos</title>
      </Helmet>
      <main className="bg-slate-200">
        <section className="relative h-[55vh]">
          <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: "url(" + HeaderPicture + ")"
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
        </section>
        <section className="relative py-16 bg-slate-200">
          <div className="container mx-auto px-4">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="flex flex-wrap justify-center">
                  <div className="w-full lg:w-3/12 px-4 lg:order-2 flex justify-center">
                    <div className="relative">
                      <img
                        alt="web applications"
                        src={HeaderPicture}
                        className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mt-12">
                  <h1 className="text-4xl font-semibold leading-normal mb-2 text-slate-700 mb-2">
                    Qui sommes-nous ?
                  </h1>
                  <div className="text-sm leading-normal mt-0 mb-2 text-slate-600 font-bold uppercase">
                    Facilitateur, Prestataire, Consultant
                  </div>
                  <div className="mb-2 text-slate-600 mt-10 font-semibold">
                    L'esprit Startup et l'innovation numérique est notre ADN.
                  </div>
                  <div className="mb-2 text-slate-600">
                    Notre métier : Vous accompagnez dans le développement de vos projets digitaux.
                  </div>
                </div>
                <div className="flex w-full flex flex-wrap mt-10 py-10 px-5 border-t border-slate-200 justify-around">
                  <div className="w-full lg:w-1/2 ">
                    <h6 className="text-center text-slate-800">DES SERVICES AU SERVICE DE VOS AMBITIONS</h6>
                    <hr className="mt-5 mx-auto  border-t-2 w-1/2 border-yellow-300"/>
                    <p className="mt-5 font-light text-slate-700 text-justify">
                      Tout juste créé en 2022, <strong>HIVEWAPP</strong> est le résultat de dizaines d'années d'expériences dans la création d'applications web et mobile.
                      Passionné par le potentiel des nouvelles technologies, <strong>la société encourage les projets digitaux afin de participer à l'économie et aux usages de demain</strong>.
                    </p>
                  </div>
                  <div className="w-full lg:w-1/2 flex justify-center items-center mt-5 md:mt-0">
                    <ul className="list-none">
                      <li className="font-semibold text-slate-800 text-sm flex flex-row items-center">
                        <FaCheck  size={18} className="mr-3 text-green-500" />
                        Du coaching pour vous rassurer
                      </li>
                      <li className="font-semibold text-slate-800 text-sm flex flex-row items-center">
                        <FaCheck  size={18} className="mr-3 text-green-500" />
                        Des séminaires pour vous informer
                      </li>
                      <li className="font-semibold text-slate-800 text-sm flex flex-row items-center">
                        <FaCheck  size={18} className="mr-3 text-green-500" />
                        Des prestations pour vous développer
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="flex w-full flex flex-wrap py-10 px-5 justify-around">
                  <div className="w-full lg:w-1/2 flex justify-center items-center">
                    <ul className="list-none">
                      <li className="font-semibold text-slate-800 text-sm flex flex-row items-center">
                        <FaCheck  size={18} className="mr-3 text-green-500" />
                        Ingénieurs et Développeurs expérimentés
                      </li>
                      <li className="font-semibold text-slate-800 text-sm flex flex-row items-center">
                        <FaCheck  size={18} className="mr-3 text-green-500" />
                        Tech Leaders et Chefs de projets affirmés
                      </li>
                      <li className="font-semibold text-slate-800 text-sm flex flex-row items-center">
                        <FaCheck  size={18} className="mr-3 text-green-500" />
                        Business Developer passionnés
                      </li>
                    </ul>
                  </div>
                  <div className="w-full lg:w-1/2 mt-5 md:mt-0">
                    <h6 className="text-center text-slate-800">NOUVEAU-NÉE ?... MAIS PAS DE LA DERNIÈRE PLUIE !</h6>
                    <hr className="mt-5 mx-auto  border-t-2 w-1/2 border-yellow-300"/>
                    <p className="mt-5 font-light text-slate-700 text-justify">
                      Avec près de <strong>10 années dans les métiers du numérique</strong> au travers de <strong>nombreuses thématiques</strong> comme la musique, la sécurité informatique, l'énergie, la santé et d'autres encore, le savoir-faire des fondateurs a été intégré pleinement dans l'offre de la société.
                      Cela se traduit par l'<strong>expertise des fondateurs</strong> acquis dans les <strong>dizaines d'applications</strong> (Mobile, Web, Desktop, Open Source) et les <strong>trentaines de technologies</strong> brassées pour le compte d'autres sociétés.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </TransparentNavLayout>
  );
}
