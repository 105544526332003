import React from "react";

export default function SimpleCTA({ title, desc, secondDesc, thirdDesc, btnTitle }: { title: string, desc: any, secondDesc: string, thirdDesc: string, btnTitle: string }) {
  return (
    <div className="relative">
      <div className="px-6 py-6 bg-slate-700 dark:bg-slate-800 md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
        <div className="xl:w-0 xl:flex-1">
          <h2 className="text-2xl font-light text-white dark:text-white sm:text-3xl">
            {title}
          </h2>
          <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
            {desc}
          </p>
        </div>
        <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
          <div className="mt-3 rounded-md shadow sm:mt-0 sm:ml-3 sm:flex-shrink-0">
            <a href="https://form.jotform.com/213491665666567?prestaType=Networking%20(gratuit)" target='_blank' rel='nofollow noreferrer' className="animate-bounce w-full flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-teal-500 hover:bg-teal-400 focus:outline-none focus:bg-teal-400 transition duration-150 ease-in-out">
              {btnTitle}
            </a>
          </div>
          <p className="mt-3 text-sm leading-5 text-indigo-200">
            {secondDesc}
          </p>
          <p className="text-sm leading-5 text-indigo-200">
            <strong>{thirdDesc}</strong>
          </p>
        </div>
      </div>
      <svg
        className="absolute -bottom-px overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-white fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  )
}
