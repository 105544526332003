import React from "react";
import { Helmet } from "react-helmet";

import { IoShareSocialOutline, IoDocumentsOutline } from 'react-icons/io5';
import { BsKanban, BsFillPersonLinesFill } from 'react-icons/bs';
import { ImBubbles4 } from 'react-icons/im';
import { MdWorkOutline } from 'react-icons/md';
import { FaRegHandshake } from 'react-icons/fa';


import OpsPicture from '../assets/img/ops.jpg';
import SalesPicture from '../assets/img/sales.jpg';

import MainLayout from "../layouts/MainLayout";
import HeaderService from "../components/HeaderService";
import SimplePricingCard from "../components/SimplePricingCard";

const OpsServices = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/ops-services" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Aide opérationnelle" />
        <meta property="og:description" content="Mettez en place les outils de base de votre productivité" />
        <meta property="og:image" content={OpsPicture} />
        <meta name="keywords" content="hivewapp, accompagnement, crm, outils de collaboration,
        consulting, conseils, réseaux sociaux, account manager, développement commercial, gestion de leads,
        prospection, lean startup, startup studio, la réunion, 974" />
        <meta name="description" content="Mettez en place les outils de base de votre productivité" />
        <title>Hivewapp - Aide opérationnelle</title>
      </Helmet>
      <HeaderService
        title="Early ops, mettez en place les bases de votre activité"
        desc="Pas besoin d'un logiciel surdimensionné pour faire ses premiers pas ! Trouvez l'outil simple, peu cher qui s'adapte à vos besoins."
        linkText="Optimiser ma productivité"
        link='https://form.jotform.com/213491665666567?prestaType=Accompagnement%20op%C3%A9rationnel'
        img={OpsPicture}
        imgSize="w-5/6"
      />
      <div className="relative bg-white dark:bg-slate-800 p-4 pb-16">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
          <div className="lg:col-start-2 md:pl-20">
            <h4 className="text-2xl font-semibold text-slate-900 dark:text-white tracking-tight">
              Nos interventions
            </h4>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <BsKanban size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                        CRM simple et adapté
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Un processus déjà en place ou vous souhaitez en formaliser une ? Trouvons et configurons ensemble l'outil qui reflète votre manière de travailler.
                        Partageons ensuite votre méthode au sein de vos équipes en dispensant une courte formation.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <IoShareSocialOutline size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                        Accompagnement initial sur les réseaux sociaux
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Votre présence en ligne passe aussi par les réseaux sociaux. Mettons en place votre page, élaborons votre ligne éditorial, votre première pub et vos premiers contenus ensemble.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <ImBubbles4 size={25} stroke="white"/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                        Outils de collaboration
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Besoin de centraliser votre communication interne, vos connaissances et vos documents ? Trouvons et configurons ensemble l'outil qui reflète votre manière de travailler.
                        Partageons ensuite votre méthode au sein de vos équipes en dispensant une courte formation.
                      </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="lg:flex justify-around">
            <SimplePricingCard
              link='https://form.jotform.com/213491665666567?prestaType=Accompagnement%20op%C3%A9rationnel'
              title="Forfait"
              price="Sur devis"
              btnText="Demander un devis"
              mode='jour ou mois'
              features={["Services sur mesure", "Avec ou sans engagement", "Entretien préalable gratuit"]}
            />
          </div>
        </div>
        <svg
          className="absolute -bottom-px overflow-hidden w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-white fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
      <section className="relative py-20">
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-teal-500">
                    <MdWorkOutline size={25} stroke="white"/>
                  </div>
                  <h3 className="text-3xl font-semibold text-slate-700">Account Manager as a Service</h3>
                  <p className="mt-4 text-lg leading-relaxed text-slate-700">
                    Déléguez une partie de votre activité technico-commerciale et gagnez du temps et de l'argent !{" "}
                    <strong>Une offre sur mesure, sans commission et sans engagement.</strong>
                  </p>
                  <ul className="list-none mt-6 mb-8">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div className="rounded-full text-white bg-teal-500 p-3 mr-5">
                          <BsFillPersonLinesFill size={25} />
                        </div>
                        <div>
                          <h4 className="text-slate-700">
                            Gestion des leads entrants
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div className="rounded-full text-white bg-teal-500 p-3 mr-5">
                            <FaRegHandshake size={25} />
                          </div>
                          <div>
                            <h4 className="text-slate-700">
                              Identifications et Qualifications des besoins
                            </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div className="rounded-full text-white bg-teal-500 p-3 mr-5">
                          <IoDocumentsOutline size={25} />
                        </div>
                        <div>
                          <h4 className="text-slate-700">
                            Formalisation des propositions technico-commerciales
                          </h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a href="https://form.jotform.com/213491665666567?prestaType=Accompagnement%20op%C3%A9rationnel&ops=Account%20Manager%20as%20a%20Service"  rel='nofollow noreferrer' target='_blank' className="font-bold text-slate-700">
                    Demander un devis
                  </a>
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4 mt-5 md:mt-5">
                <img
                  alt="people reading a contract"
                  className="max-w-full rounded-lg shadow-lg"
                  src={SalesPicture}
                />
              </div>
            </div>
          </div>
          <svg
          className="absolute -bottom-px overflow-hidden w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-slate-200 fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
        </section>
    </MainLayout>
  );
};

export default OpsServices;
