import React from "react";

export default function FeatureItem({ icon, title, desc }: { icon: any, title: string, desc: any }) {
  return (
    <div className="w-full lg:w-3/12 px-4 text-center">
      <div className="text-white p-3 w-14 h-14 shadow-lg rounded-full bg-teal-400 inline-flex items-center justify-center">
        {icon}
      </div>
      <h6 className="text-xl mt-5 font-semibold text-white">
        {title}
      </h6>
      <p className="mt-2 mb-4 text-slate-200">
        {desc}
      </p>
    </div>
  )
}
