import React from "react";

export default function Testimonial({ img, name, role, company, hashtags, desc }: { img: string, name: string, role: string, company: string, hashtags: string[], desc: string }) {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col p-3">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden flex-1 flex flex-col p-4">
        <div className="flex items-start text-left">
          <div className="flex-shrink-0">
            <div className="inline-block relative">
              <span className="block relative">
                <img alt={`${name}, ${role}, ${company}`} src={img} className="mx-auto object-cover rounded-full h-16 w-16 "/>
              </span>
            </div>
          </div>
          <div className="ml-6">
            <div className="flex items-baseline flex-wrap">
              <h5 className="text-gray-600 font-bold">
                {name}
              </h5>
              <h6 className="text-gray-500 ml-0 md:ml-2 text-sm">
                {role}@{company} 
              </h6>
            </div>
            <div className="flex items-center mt-1 flex-wrap">
              {
                hashtags.map(hashtag => 
                  <span key={hashtag} className="m-1 whitespace-nowrap inline-flex bg-gray-200 text-gray-600 text-xs rounded-full py-1 px-2 justify-center items-center">
                    #{hashtag}
                  </span>
                )
              }
            </div>
            <div className="mt-3">
              <span className="font-bold text-orange-500 text-xl mr-1 font-serif">
                “
              </span>
              <p className="p-0 m-0 inline italic text-slate-800">{desc}</p>
              <span className="font-bold text-orange-500 text-xl ml-1 font-serif">
                ”
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
