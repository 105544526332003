import React from "react";
import { Helmet } from "react-helmet";

import ProtoPicture from '../assets/img/prototype.jpg';

import MainLayout from "../layouts/MainLayout";
import HeaderService from "../components/HeaderService";

import AutoPicture from '../assets/img/apps/autolini.jpg'
import MeelyPicture from '../assets/img/apps/meely.jpg'
import LimifyPicture from '../assets/img/apps/limify.jpg'
import QualioPicture from '../assets/img/apps/qualiocoach.jpg'
import DekiloPicture from '../assets/img/apps/dekilo.jpg'
import NutrinerPicture from '../assets/img/apps/nutriner.jpg'
import GramounPicture from '../assets/img/apps/gramounpei.jpg'
import BankPicture from '../assets/img/apps/bank.jpg'
import RideCarPicture from '../assets/img/apps/ridecarxp.jpg'



const apps: any[] = [
  { link: 'https://autolini.web.app', alt:"application de gestion d'un atelier mécanique", img:AutoPicture,  name: 'Autolini', desc: "Gestion d'ateliers mécaniques"},
  { link: 'https://dekilo.gitlab.io', alt:"location de transports non carbonnés entre particuliers", img:DekiloPicture,  name: 'Dekilo', desc: "Location de transports entre particuliers"},
  { link: 'https://qualiocoach.web.app', alt:"Application d'aides à la certification Qualiopi pour les centres de formations", img:QualioPicture,  name: 'QualioCoach', desc: "Application d'aide à Qualiopi"},
  { link: 'https://nutriner.web.app', alt:"application communautaire en alimentation", img:NutrinerPicture,  name: 'Nutriner', desc: "Application communautaire en alimentation"},
  { link: 'https://meely.gitlab.io', alt:"application de rencontres émotionnelles", img:MeelyPicture,  name: 'Meely', desc: "Application de rencontres émotionnelles"},
  { link: 'https://limify.github.io', alt:"blockchain de crypto-monnaie d'actions environnementales", img:LimifyPicture,  name: 'Limify', desc: "Crypto-monnaie d'actions environnementales"},
  { link: 'https://gramounpei.github.io', alt:"application de services aux séniors", img:GramounPicture,  name: 'GramounPeï', desc: "Application de services sociaux et digitales pour les séniors"},
  { link: 'https://ackmydebt.web.app', alt:"application de reconnaissance de dettes via la blockchain", img:BankPicture,  name: 'Ack My Debt', desc: "Application de reconnaissance de dettes numériques"},
  { link: '#', alt:"plateforme des passionnés de l'automobile", img:RideCarPicture,  name: 'Ride Car Experience', desc: "Plateforme de mise en relation entre passionés de l'automobile"},
  { link: 'https://scenup-f3fe3.web.app/', alt:"outil de scénarisation pédagogique", img:QualioPicture,  name: 'ScenUp', desc: "Outil de scénarisation pédagogique"},
]

const Projects = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/projects" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Prototypes et autres applications web et mobile" />
        <meta property="og:description" content="Retrouvez nos créations et nos prototypes d'applications web et mobile" />
        <meta property="og:image" content={ProtoPicture} />
        <meta name="keywords" content="hivewapp, prototypage, web applications, applications mobiles,
        prototype web et mobile, blockchain, mvp, développement informatique, landing page, outils technologiques,
        lean startup, architecture techniques, startup studio, la réunion, 974" />
        <meta name="description" content="Retrouvez nos créations et nos prototypes d'applications web et mobile" />
        <title>Hivewapp - Prototypes et autres applications web et mobile</title>
      </Helmet>
      <HeaderService
        title="Nos réalisations d'applications web et mobile"
        desc="Parce que nous sommes avant tout des experts dans la création d'applications, nous disposons de prototypes dans divers domaines d'activités. Nous brassons ainsi une large gamme de technologies et de produits."
        img={ProtoPicture}
      />
      <div className="relative bg-white dark:bg-slate-800 p-4 pb-16">
        <div className="container mx-auto p-8 rounded-lg">
          <h2 className="text-center text-3xl font-bold text-gray-800 dark:text-white">
            Notre ruche d'applications
          </h2>
          <h6 className="text-center mb-12 text-xl font-normal text-gray-500 dark:text-gray-300">
            Des expériences diverses et variées dans de nombreuses secteurs grâce à de nombreuses technologies
          </h6>
          <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
            {
              apps.map(app => <div key={app.name} className="p-4">
              <div className="flex-col  flex justify-center items-center">
                <div className="flex-shrink-0">
                  <a href={app.link} className="block relative" target={'_blank'} rel='nofollow noreferrer'>
                    <img alt={app.alt} src={app.img} className="mx-auto object-cover rounded-full h-20 w-20 "/>
                  </a>
                </div>
                <div className="mt-2 text-center flex flex-col">
                  <span className="text-gray-600 dark:text-white text-lg font-medium">
                    {app.name}
                  </span>
                  <span className="text-gray-400 text-xs">
                    {app.desc}
                  </span>
                </div>
              </div>
            </div>)
            }
          </div>
          <div className="">
              <div className="lg:flex lg:items-center lg:justify-between w-full mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
                  <h2 className="text-2xl font-semibold text-black dark:text-white sm:text-3xl">
                    <span className="block">
                      Envie de porter un projet ?
                    </span>
                    <span className="text-sm text-gray-300">
                      Nos prototypes sont à la recherche de la bonne équipe pour les propulser.
                    </span>
                  </h2>
                  <div className="lg:mt-0 lg:flex-shrink-0">
                    <div className=" inline-flex rounded-md">
                      <a href="mailto:contact@hivewapp.com?subject=Devenir porteur de projet" className="animate-bounce py-4 px-6 bg-teal-600 hover:bg-teal-700 focus:ring-teal-500 focus:ring-offset-teal-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        Postuler
                      </a>
                    </div>
                  </div>
              </div>
          </div>
        </div>
        <svg
          className="absolute -bottom-px overflow-hidden w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-slate-200 fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </MainLayout>
  );
};

export default Projects;
