import React from "react";
import { Helmet } from "react-helmet";

import TechPicture from '../assets/img/tech.jpg';
import OpsPicture from '../assets/img/ops.jpg';
import LaunchPicture from '../assets/img/launch.jpg';
import ConsultingPicture from '../assets/img/consulting.png';

import SimpleCTA from "../components/SimpleCTA";
import TransparentNavLayout from "../layouts/TransparentNavLayout";
import BigFeatureSection from "../components/BigFeatureSection";
import BigHeader from "../components/BigHeader";
import ValueSection from "../components/ValueSection";
import Testimonial from "../components/Testimonial";
import TestimonialActions from "../components/TestimonialActions";

const LandingPage = () => {
  return (
    <TransparentNavLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Cabinet de conseil pour Startups technologiques" />
        <meta property="og:description" content="Hivewapp vous accompagne dans la création de vos projets digitaux afin d'améliorer les chances de succès de votre startup." />
        <meta property="og:image" content={ConsultingPicture} />
        <meta name="keywords" content="hivewapp, web applications, applications, innovation,
          services numériques, accompagnement, projet, logiciels, software, idée, mvp, mise à l'échelle,
          scale, développement informatique, développement commercial, minumum valuable product,
          lean startup, product ownering, conception produit, startup studio, la réunion, 974" />
        <meta name="description" content="Hivewapp vous accompagne dans la création de vos projets digitaux afin d'améliorer les chances de succès de votre startup." />
        <title>Hivewapp - Cabinet de conseil pour Startups technologiques</title>
      </Helmet>
      <main className="bg-slate-200">
        <BigHeader
          bgColor='bg-teal-600'
          dividerColor='text-white'
          title="Cabinet de conseil"
          subtitle="pour Startups technologiques"
          linkText="Découvrez comment"
          desc={<>
              <strong className="italic">En voie de développer votre application ?</strong>{" "}
              <span className='font-light'>Nous vous accompagnons dans la création de vos projets digitaux afin d'améliorer les chances de succès de votre startup.</span>
            </>
          }
          img={ConsultingPicture}
          link='technologies'
        />
        <BigFeatureSection
          title="Spécialistes dédiés à l'accompagnement technologique"
          desc={<>
              <strong className="italic">Difficile d'avoir un spécialiste technologique surtout quand on démarre ?</strong>{" "}
              <span className='font-light'>Que ce soit pour les choix stratégiques ou opérationnels, évitez les erreurs en dehors de votre champ de compétences et optez pour une intervention régulière ou à la demande.</span>
            </>
          }
          img={TechPicture}
          link='/technology-services'
          id='technologies'
        />
        
        <SimpleCTA
          title="Besoin d'autres spécialistes ?"
          desc="UX/UI Designers ? Développeurs ? Devs ops ? Ne cherchez plus ! Notre équipe se fera un plaisir de vous accompagner."
          btnTitle="Expliquez mon besoin gratuitement"
          secondDesc="Faire appel à nous, c'est aussi entrer dans l'écosystème des startups du numérique à la Réunion, en Outre-mer et dans la zone Océan Indien."
          thirdDesc="C'est aussi ça notre métier, vous aiguillez vers les spécialistes dont vous avez besoin pour développer votre startup."
        />

        <BigFeatureSection
          title="Explorez, structurez et validez votre produit numérique"
          desc={<>
            <strong className="italic">Vous avez une idée à développer ?</strong> <span className='font-light'>Alors donnons lui de la valeur !{" "}</span>
            <strong className="italic">Vous ne savez pas par où commencer ?</strong> <span className='font-light'>Nous oui ! {" "}</span>
            <strong className="italic">Besoin de tester votre produit avec suffisamment de recul ?</strong> <span className='font-light'>Comptez sur nous !</span>
            </>
          }
          leftPicture
          linkText='Commençons maintenant'
          img={LaunchPicture}
          link='/product-services'
        />

        <ValueSection />

        <BigFeatureSection
          title="Early ops, mettez en place les bases de votre activité"
          img={OpsPicture}
          link='/ops-services'
          desc={<>
              <strong className="italic">Vous avez besoin d'un minimum de structuration ?</strong>{" "}
              <span className='font-light'>CRM, Outils collaboratif, Réseaux sociaux, faîtes vos premiers pas en découvrant nos services qui faciliteront votre activité.</span>
            </>
          }
          linkText='Découvrir nos services'
          dividerColor='text-slate-200'
        />
        <h4 className="text-2xl sm:text-3xl lg:text-4xl font-semibold text-slate-700 text-center">Les startups avec qui nous travaillons</h4>
        <div className="container mx-auto flex flex-wrap p-8 justify-center">
          <Testimonial
            img="https://media.licdn.com/dms/image/D4E03AQGwUpVH2MwWEQ/profile-displayphoto-shrink_400_400/0/1671344607441?e=1678320000&v=beta&t=GZ7rXvRJhWGj3MCkUaqB00T0iFofIHrt0PBI_q-pKNw"
            name='Vigile'
            role='CEO'
            company='Crowdaa'
            hashtags={['e-marketing', 'landing page']}
            desc="Nos espaces d'échanges ont pu enrichir notre vision tant sur le plan opérationnel que stratégique."
          />
          <Testimonial
            img="https://media.licdn.com/dms/image/C4E03AQH8HeyEfQYmmg/profile-displayphoto-shrink_400_400/0/1657978734458?e=1678320000&v=beta&t=quwm-J2uOj8MUPMC_E7AYKYe_hmZkzbANcHEoI-ZQDg"
            name='Brigitte'
            role='CEO'
            company='Lakazmatante'
            hashtags={['idéation', 'pitch', 'mvp']}
            desc="Après 54h de concours, mon projet a terminé sur le podium grâce à la réactivité, à l'analyse et à la bienveillance. Merci Hivewapp !"
          />
          <Testimonial
            img="data:image/png;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"
            name='Myriam'
            role='CEO'
            company="Hop'Soins"
            hashtags={['app mobile', 'conception', 'mvp']}
            desc="Merci à Hivewapp pour son efficacite, sa vision tech et business et sa disponibilite."
          />
          <TestimonialActions />
        </div>
      </main>
    </TransparentNavLayout>
  );
};

export default LandingPage;
