import React from "react";

import {IoRocketSharp } from 'react-icons/io5'
import { Link } from "react-router-dom";

export default function TestimonialActions() {
  return (
    <div className="w-full sm:w-1/2 md:w-1/3 flex flex-col p-3">
      <div className="bg-white rounded-lg shadow-lg overflow-hidden flex-1 flex flex-col p-4">
        <div className="flex items-start text-left h-full">
          <div className="flex-shrink-0">
            <div className="inline-block relative">
              <span className="block relative bg-orange-500 rounded-full">
                <IoRocketSharp className="text-white text-6xl p-3"/>
              </span>
            </div>
          </div>
          <div className="ml-6 flex flex-col flex-1 h-full">
            <div className="flex items-baseline">
              <h5 className="text-gray-600 font-bold">
                Proof of Concept
              </h5>
            </div>
            <div className="mt-3">
              <span className="font-bold text-orange-500 text-xl mr-1 font-serif">
                “
              </span>
              <p className="p-0 m-0 inline italic text-slate-800 ">
                Retrouvez les prototypes ou les landing pages de nos différents projets.
              </p>
              <span className="font-bold text-orange-500 text-xl ml-1 font-serif">
                ”
              </span>
              <div className="mt-5 -ml-12 text-center">
                <Link to='/projects' className="font-bold text-teal-500">
                  Découvrir
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
