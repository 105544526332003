import React from "react";
import { Link } from "react-router-dom";

export default function BigFeatureSection({ id, bgColor='bg-white dark:bg-white', title, desc, linkText = 'En savoir plus', leftPicture, link, img, dividerColor="text-slate-800" }: { id?: string, title: string, desc: any, linkText?: string, leftPicture?:boolean, link: string, img: any, dividerColor?: string, bgColor?: string  }) {
  return (
    <div className="relative" id={id}>
      <div className={`${bgColor} px-10 lg:px-24 overflow-hidden relative lg:flex lg:items-center`}>
        {
          leftPicture && <div className="w-11/12">
            <img src={img} className="" alt="people working on computer"/>
          </div>
        }
        <div className="w-full py-12 px-4 sm:px-6 lg:py-16 lg:px-8 z-20">
            <h2 className="text-2xl font-extrabold text-slate-400 dark:text-slate-800 sm:text-3xl">
              <span className="block">
                {title}
              </span>
            </h2>
            <p className="text-md mt-4 text-gray-700 leading-relaxed">
              {desc}
            </p>
            <div className="lg:mt-0 lg:flex-shrink-0">
              <div className="mt-12 rounded-md flex justify-center">
                <Link to={link} className="py-2 px-4 bg-orange-400 hover:bg-orange-500 focus:ring-orange-400 focus:ring-offset-orange-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                  {linkText}
                </Link>
              </div>
            </div>
        </div>
        {
          !leftPicture && <div className="w-11/12">
            <img src={img} className="" alt="people working on computer"/>
          </div>
        }
      </div>
      <svg
        className="absolute -bottom-px overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className={`${dividerColor} fill-current`}
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  )
}
