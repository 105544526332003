import React from "react";

export default function HeaderService({ title, desc, linkText = 'En savoir plus', link, img, dividerColor="text-slate-800", imgSize="" }: { title: string, desc: any, linkText?: string, link?: string, img: any, dividerColor?: string, imgSize?: string  }) {
  return (
    <div className="relative">
      <div className="h-full lg:h-[75vh] pt-20 items-center container mx-auto lg:flex">
        <div className="px-4 lg:w-1/2">
          <h1 className="font-semibold text-3xl text-slate-700">
            {title}
          </h1>
          <p className="mt-4 text-lg leading-relaxed font-light text-slate-700">
            {desc}
          </p>
          {
            link && linkText
            && <div className="mt-12 flex justify-center">
            <a
              href={link}
              target="_blank"
              rel='nofollow noreferrer'
              className="text-center text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-teal-500 active:bg-teal-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
            >
              {linkText}
            </a>
          </div>
          }
        </div>
        <div className="pt-16 lg:pt-0 flex justify-center lg:w-1/2">
          <img
            className={imgSize}
            src={img}
            alt="people working on computer"
          />
        </div>
      </div>
      <svg
        className="absolute -bottom-px overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className={`${dividerColor} fill-current`}
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  )
}
