import React from 'react';
import {
  Routes,
  Route,
} from "react-router-dom";

import Landing from "./pages/Landing";
import Legal from "./pages/Legal";
import TechServices from "./pages/TechServices";
import ProductServices from "./pages/ProductServices";
import OpsServices from "./pages/OpsServices";
import TrainingServices from "./pages/TrainingServices";
import Projects from "./pages/Projects";
import About from "./pages/About";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/legal" element={<Legal />} />
      <Route path="/technology-services" element={<TechServices />} />
      <Route path="/product-services" element={<ProductServices />} />
      <Route path="/ops-services" element={<OpsServices />} />
      <Route path="/training-services" element={<TrainingServices />} />
      <Route path="/about" element={<About />} />
      <Route path="/projects" element={<Projects />} />
      {/* add redirect for first page */}
      <Route
        path="*"
        element={<Landing/>}
      />
    </Routes>
  );
}

export default App;
