import React from "react";
import { Helmet } from "react-helmet";

import { FaBong } from 'react-icons/fa';
import { IoRocketOutline } from 'react-icons/io5';
import { HiOutlinePresentationChartLine, HiOutlineLightBulb } from 'react-icons/hi';


import LaunchPicture from '../assets/img/launch.jpg';

import MainLayout from "../layouts/MainLayout";
import HeaderService from "../components/HeaderService";
import SimplePricingCard from "../components/SimplePricingCard";

const ProductServices = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/product-services" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Conception produit" />
        <meta property="og:description" content="Concevez votre produit en l'explorant, le structurant et en le testant" />
        <meta property="og:image" content={LaunchPicture} />
        <meta name="keywords" content="hivewapp, conception produit, déinition produit, roadmap produit,
        consulting, conseils, pitch coaching, minimum viable product scoping, tests, mvp, feedback,
        lean startup, key features, fonctionnalités produit, startup studio, la réunion, 974" />
        <meta name="description" content="Concevez votre produit en l'explorant, le structurant et en le testant" />
        <title>Hivewapp - Conception produit</title>
      </Helmet>
      <HeaderService
        title="Explorez, structurez et validez votre produit numérique"
        desc="Une idée ne vaut rien alors partageons nos efforts pour lui donner la valeur qu'elle mérite. Bien définir votre produit et votre audience est essentielle pour réussir où les autres ont échoués."
        linkText="Améliorer mes chances de succès"
        link='https://form.jotform.com/213491665666567?prestaType=Accompagnement%20produit'
        img={LaunchPicture}
      />
      <div className="relative bg-white dark:bg-slate-800 p-4 pb-16">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
          <div className="lg:col-start-2 md:pl-20">
            <h4 className="text-2xl font-semibold text-slate-900 dark:text-white tracking-tight">
              Nos prestations
            </h4>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <HiOutlineLightBulb size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Séances d'idéation
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Vous connaissez très probablement les bienfaits du brainstorming ? Audience, Proposition de valeur, fonctionnalités et modèle économique, abordons ensemble vos perspectives et stratégies produits.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <HiOutlinePresentationChartLine size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Pitch coaching
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Trouvez une oreille attentive pour préparer vos soutenances orales autant de fois que nécessaire.
                        Obtenez un oeil avisé sur vos supports de présentation et une prépration aux Questions / Réponses.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <IoRocketOutline size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Minimum Viable Product Scoping
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Beaucoup font encore cette erreur. Pas besoin d'une application fonctionnelle complète pour lancer votre projet. Une simple vidéo peut parfois suffir.
                        Avant une telle décision, établissez vos objectifs et l'expérience utilisateur afin d'en déduire les key features et le format de votre MVP.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <FaBong size={25} stroke="white"/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Tests et retours d'expériences
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Nous proposons des tests effectués par nos équipes sur des scénarios établis par vos soins.
                        Nous fournissons aussi notre retour d'expérience globale. Nous nous adaptons au format de vos applications (iOS, Android, Web, Desktop).
                      </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="lg:flex justify-around">
            <SimplePricingCard
              link='https://form.jotform.com/213491665666567?prestaType=S%C3%A9ance'
              title="Séance"
              btnText="Prendre RDV"
              price="149 €"
              mode='séance'
              features={["Livré avec le bilan", "2 heures d'échange", "Paiement à la fin de la séance"]}
            />
            <SimplePricingCard
              link='https://form.jotform.com/213491665666567?prestaType=Accompagnement%20produit'
              title="Forfait"
              price="Sur devis"
              btnText="Demander un devis"
              mode='jour ou mois'
              features={["Services sur mesure", "Avec ou sans engagement", "Entretien préalable gratuit"]}
            />
          </div>
        </div>
        <svg
          className="absolute -bottom-px overflow-hidden w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-slate-200 fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </MainLayout>
  );
};

export default ProductServices;
