import React from "react";
import IndexNavbar from "../components/IndexNavbar";
import Footer from "../components/Footer";

export default function MainLayout({ children }: { children: any}) {
  return (
    <main>
      <IndexNavbar />
      {children}
      <Footer/>
    </main>
  )
}
