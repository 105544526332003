import React from "react";
import { FaArrowDown } from 'react-icons/fa';

export default function BigHeader({ bgColor='bg-white dark:bg-white', title, subtitle, desc, linkText = 'En savoir plus', link, img, dividerColor="text-slate-800" }: { title: string, desc: any, linkText?: string, link: string, img: any, dividerColor?: string, bgColor?: string, subtitle?: string  }) {
  return (
    <div className="relative">
      <div className={`${bgColor} py-12 md:py-16 flex flex-col md:flex-row px-10 lg:px-24`}>
        <div className="flex flex-1 flex-col justify-center align-center">
            <h1 className="text-white text-2xl font-bold sm:text-3xl lg:text-4xl pb-5">
              {title}
              <br/>
              {subtitle}
            </h1>
            <p className="text-white md:pr-10 text-lg">
              {desc}
            </p>
            <div className="mt-5 text-center">
              <button onClick={
                () =>{
                  const anchor = document.getElementById(link)
                  if(anchor) {
                    anchor.scrollIntoView()
                  }
                }}
                className="flex p-4 animate-bounce text-white font-semibold items-center">
               {linkText} <FaArrowDown className='ml-2' /> 
              </button>
            </div>
        </div>
        <div className="flex flex-1 justify-center align-center pt-5 md:pt-0">
          <img src={img} className="w-2/3" alt="one person is advising another one"/>
        </div>
      </div>
      <svg
        className="absolute -bottom-px overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className={`${dividerColor} fill-current`}
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  )
}
