import React from "react";

import { BiTimer, BiTrophy } from 'react-icons/bi';
import { RiFocus3Line } from 'react-icons/ri';

import FeatureItem from "../components/FeatureItem";

export default function ValueSection() {
  return (
    <div className="relative">
      <div className="bg-slate-800 pb-5">
        <div className="flex flex-wrap text-center justify-center">
          <div className="w-full lg:w-6/12 px-4">
            <h2 className="text-4xl font-semibold text-white">
              Pourquoi faire appel à nous ?
            </h2>
            <p className="text-lg leading-relaxed mt-4 mb-4 text-slate-200">
              <q>
                Tout seul on va plus vite, ensemble, on va plus loin.
              </q>
            </p>
          </div>
        </div>
        <div className="flex flex-wrap mt-12 justify-center">
          <FeatureItem
            title="Rapidité"
            icon={<BiTimer className="text-4xl"/>}
            desc={<>
              <strong>Réduisez le temps et l'investissement</strong> entre la conception et le lancement de votre projet pour le <strong>concrétiser le plus tôt possible</strong>.
            </>}
          />
          <FeatureItem
            title="Sérénité"
            icon={<BiTrophy className="text-4xl"/>}
            desc={<>
              <strong>Réduisez les risques d'échecs</strong> et atteignez le Product Marker Fit au plus tôt en <strong>faisant les bons choix</strong>.
            </>}
          />
          <FeatureItem
            title="Business Focus"
            icon={<RiFocus3Line className="text-4xl"/>}
            desc={<>
              <strong>Concentrez-vous sur votre proposition de valeur</strong> et votre coeur de métier et{" "}
                <strong>déléguez les compétences</strong> que nous n'avez pas.
            </>}
          />
        </div>
      </div>
      <svg
        className="absolute -bottom-px overflow-hidden"
        xmlns="http://www.w3.org/2000/svg"
        preserveAspectRatio="none"
        version="1.1"
        viewBox="0 0 2560 100"
        x="0"
        y="0"
      >
        <polygon
          className="text-white fill-current"
          points="2560 0 2560 100 0 100"
        ></polygon>
      </svg>
    </div>
  )
}
