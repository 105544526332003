import React from "react";

export default function SimplePricingCard({ title, price, btnText, mode, features, link } : { title: string, price: string, btnText: string, mode: string, features:string[], link: string }) {
  return (
    <div className="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
      <div className="w-72 mx-auto bg-white border-t-4 rounded border-indigo-500 dark:bg-white-800 shadow text-center p-4">
        <div className="overflow-hidden">
          <div className="text-2xl font-medium mb-8 text-slate-800">
            {title}
          </div>
          <div className="leading-loose text-sm font-light text-slate-700 dark:text-slate-700 mb-10">
              { features.map((f, i) => <div key={i} className={`${i === 0 && "font-bold" }`}>
                {f}
              </div>)}
          </div>
          <div className="font-bold text-2xl mb-2 text-slate-500 dark:text-slate-700">
            <span>
              {price}
            </span>
          </div>
          <div className="text-slate-500 dark:text-slate-700 text-sm">
            / {mode}
          </div>
          <div className="px-4 mt-8 mb-2">
            <a
              href={link}
              target="_blank"
              rel='nofollow noreferrer'
              className="py-2 px-4 bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-indigo-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
              {btnText}
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
