/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import { FaLinkedinIn, FaBars } from 'react-icons/fa';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <>
      <nav className="top-0 fixed z-50 w-full flex flex-wrap items-center justify-between px-2 py-3 navbar-expand-lg bg-white shadow">
        <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <Link
              to="/"
              className="text-blueGray-700 text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"
            >
              HIVEWAPP
            </Link>
            <button
              className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <FaBars className="text-slate-600" />
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center bg-white lg:bg-opacity-0 lg:shadow-none" +
              (navbarOpen ? " block" : " hidden")
            }
            id="example-navbar-warning"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            <li className="flex items-center">
                <Link
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  to="/technology-services"
                >
                  Accompagnement technologique
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  to="/product-services"
                >
                  Conception produit
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  to="/ops-services"
                >
                  Aide opérationnelle
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  to="/training-services"
                >
                  Séminaires
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  to="/projects"
                >
                  Nos réalisations
                </Link>
              </li>
              <li className="flex items-center">
                <Link
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  to="/about"
                >
                  À propos
                </Link>
              </li>
              <li className="flex items-center">
                <a
                  className="lg:hover:text-slate-600 text-slate-700 px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"
                  href="https://www.linkedin.com/company/hivewapp"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaLinkedinIn className="text-slate-600 text-xl leading-lg" />
                  <i className=" fab fa-linkedin-in  " />
                  <span className="lg:hidden inline-block ml-2">LinkedIn</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
