import React from "react";
import { Helmet } from "react-helmet";

import { IoRocketOutline } from 'react-icons/io5';
import { BsKanban } from 'react-icons/bs';
import { MdWorkOutline } from 'react-icons/md';
import { FaCodeBranch, FaCode, FaCloud } from 'react-icons/fa';


import TrainingPicture from '../assets/img/training.jpg';
import DevPicture from '../assets/img/dev.jpg';
import ProjectPicture from '../assets/img/project.jpg';

import MainLayout from "../layouts/MainLayout";
import HeaderService from "../components/HeaderService";

const TrainingServices = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/training-services" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Séminaires technologiques et méthodologiques" />
        <meta property="og:description" content="Apprenez à communiquer et à gérer votre équipe technique en découvrant les derniers outils technologiques" />
        <meta property="og:image" content={TrainingPicture}/>
        <meta name="keywords" content="hivewapp, séminaires, web applications, applications,
        apprendre, accompagnement, projet, mise à l'échelle,
        scale, développement informatique, méthodologie, framework, outils technologiques,
        lean startup, product ownering, conception produit, startup studio, la réunion, 974" />
        <meta name="description" content="Apprenez à communiquer et à gérer votre équipe technique en découvrant les derniers outils technologiques" />
        <title>Hivewapp - Séminaires technologiques et méthodologiques</title>
      </Helmet>
      <HeaderService
        title="Ne laissez pas la technologie vous dépassez"
        desc="Ne restez pas sans voix face à vos interlocuteurs techniques. Et parce que ce domaine évolue très vite, ne ratez pas les opportunités en restant informé. Apprenez à communiquer et à gérer votre équipe technique en découvrant les derniers outils technologiques."
        linkText="Je veux un séminaire spécifique"
        link='https://form.jotform.com/213491665666567?prestaType=Formation&formation=Autre'
        img={TrainingPicture}
        dividerColor={"text-slate-200"}
      />
      <div className="relative pb-16">
        <div className="relative pb-8 lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center dark:bg-slate-200">
          <div className="lg:flex justify-around">
            <div className="w-full md:w-8/12 px-4 mr-auto ml-auto">
              <div className="relative flex flex-col min-w-0 break-words bg-teal-500 w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="séminaire sur la programmation"
                  src={DevPicture}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <h4 className="text-xl font-bold text-white">
                    Séminaires pour les non-initiés
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    Nos séminaires ne requirent aucune compétence technique. Elles vous permettent avant tout d'appréhender le vocabulaire technique afin de faciliter le pilotage de vos équipes de développement.
                    Avant chaque séminaire, nous déterminons votre besoin ainsi que votre niveau initial.
                  </p>
                </blockquote>
              </div>
            </div>
          </div>
          <div className="w-full px-4">
            <div className="flex flex-wrap">
              <div className="w-full md:w-6/12 px-4">
                <div className="relative flex flex-col mt-4">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-teal-500">
                      <FaCodeBranch size={25} />
                    </div>
                    <h6 className="text-xl mb-1 font-semibold text-slate-700">
                      Pourquoi et comment gérer les versions de son produit ?
                    </h6>
                    <p className="mb-4 text-slate-700">
                      Git, Github, Gitlab, des outils pour sécuriser et capitaliser vos activités tout en organisant vos équipes.
                    </p>
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-teal-500">
                      <FaCloud size={25} />
                    </div>
                    <h6 className="text-xl mb-1 font-semibold text-slate-700">
                      Le no-code pour faciliter et accélérer vos projets
                    </h6>
                    <p className="mb-4 text-slate-700">
                      Découvrez le No-Code et ses limites. Créez une application en quelques heures.
                    </p>
                  </div>
                </div>
              </div>
              <div className="w-full md:w-6/12 px-4">
                <div className="relative flex flex-col min-w-0 mt-4">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-teal-500">
                      <FaCode size={25} />
                    </div>
                    <h6 className="text-xl mb-1 font-semibold text-slate-700">
                      Framework front-end, du sur mesure rapide, flexible et efficace
                    </h6>
                    <p className="mb-4 text-slate-700">
                      Focus sur ReactJS, soyez à la fine pointe de la technologie.
                      Abordez les concepts importants d'une application web.
                    </p>
                  </div>
                </div>
                <div className="relative flex flex-col min-w-0">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-teal-500">
                      <FaCode size={25} />
                    </div>
                    <h6 className="text-xl mb-1 font-semibold text-slate-700">
                      Application web, hybride ou native ? Quelles technologies pour quels besoins ?
                    </h6>
                    <p className="mb-4 text-slate-700">
                      Présentation globale des opportunités pour créer votre application mobile. Découvrez React Native, le framework native multi-plateforme
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <svg
            className="absolute -bottom-px overflow-hidden w-full"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className={`text-white fill-current`}
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>
        <section className="relative mt-5 bg-white">
          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-white p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-teal-500">
                    <MdWorkOutline size={25} stroke="white"/>
                  </div>
                  <h3 className="text-2xl font-semibold text-slate-700">Des méthodologies pour améliorer vos chances de succès</h3>
                  <p className="mt-4 text-lg leading-relaxed text-slate-700">
                    Beaucoup de startups échouent ou gaspillent énormément de temps et d'argent pour développer des fonctionnalités non pertinentes pour ses utilisateurs finaux.
                  </p>
                  <ul className="list-none mt-6 mb-8">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div className="rounded-full text-white bg-teal-500 p-3 mr-5">
                          <BsKanban size={25} />
                        </div>
                        <div>
                          <h4 className="text-slate-700 font-semibold">
                            Lean Startup ou comment définir son produit numérique ?
                          </h4>
                          <p className="text-slate-700">
                            Prototype, Minimum Viable Product, Impact Mapping, des outils pour clarifier votre vision et organiser votre feuille de route.
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div className="rounded-full text-white bg-teal-500 p-3 mr-5">
                            <IoRocketOutline size={25} />
                          </div>
                          <div>
                            <h4 className="text-slate-700 font-semibold">
                              Leader vos projets en faisant face aux changements
                            </h4>
                            <p className="text-slate-700">
                              Tout le monde en parle, mais que savez-vous vraiment de l'agilité et de la méthode Scrum ?
                              Comment mettre à l'échelle de votre produit ou de vos équipes produits ?
                            </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <a
                    href="https://form.jotform.com/213491665666567?prestaType=Formation"
                    target="_blank"
                    rel='nofollow noreferrer'
                    className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-orange-400 active:bg-orange-500 hover:bg-orange-500 focus:ring-orange-400 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                  >
                    Je choisis mon séminaire
                  </a>
                </div>
              </div>
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4 mt-5 md:mt-5">
                <img
                  alt="people working together"
                  className="max-w-full rounded-lg shadow-lg"
                  src={ProjectPicture}
                />
              </div>
            </div>
          </div>
        </section>
        <svg
          className="absolute -bottom-px overflow-hidden w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-slate-200 fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </MainLayout>
  );
};

export default TrainingServices;
