import React from "react";
import { Helmet } from "react-helmet";

import { FaPeopleArrows, FaTools } from 'react-icons/fa';
import { MdAppSettingsAlt } from 'react-icons/md';


import TechPicture from '../assets/img/tech.jpg';

import MainLayout from "../layouts/MainLayout";
import HeaderService from "../components/HeaderService";
import SimplePricingCard from "../components/SimplePricingCard";

const TechServices = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/technology-services" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Accompagnement techonologique" />
        <meta property="og:description" content="Déléguez votre supervision ou vos spécifications auprès de nos experts. Trouvez et déployez le bon outil pour votre Minimum Viable Product" />
        <meta property="og:image" content={TechPicture} />
        <meta name="keywords" content="hivewapp, accompagnement, web applications, applications mobiles,
        consulting, conseils, projet, supervision, spécifications, mvp, développement informatique, méthodologie, landing page, outils technologiques,
        lean startup, architecture techniques, startup studio, la réunion, 974" />
        <meta name="description" content="Déléguez votre supervision ou vos spécifications auprès de nos experts. Trouvez et déployez le bon outil pour votre Minimum Viable Product" />
        <title>Hivewapp - Accompagnement techonologique</title>
      </Helmet>
      <HeaderService
        title="Spécialistes dédiés à l'accompagnement technologique"
        desc="Faire appel à une agence ou construire sa propre équipe ? Comment créer une équipe en interne ? Se faire comprendre par l'équipe technique ? Raccourcir l'effort technologique ? Besoin d'une AMOA ?"
        linkText="J'ai une question"
        link='https://form.jotform.com/213491665666567?prestaType=Accompagnement%20technologique'
        img={TechPicture}
      />
      <div className="relative bg-white dark:bg-slate-800 p-4 pb-16">
        <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
          <div className="lg:col-start-2 md:pl-20">
            <h4 className="text-2xl font-semibold text-slate-900 dark:text-white tracking-tight">
              Nos services
            </h4>
            <ul className="mt-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <FaPeopleArrows size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Supervision
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Internalisation ou externalisation de votre équipe technique. Assistance à la Maîtrise d'Ouvrage. Mise en place d'une gestion de projet.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <FaTools size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Spécifications
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Consultation technologique. Conception et rédaction des spécifications.
                      </p>
                  </div>
                </div>
              </li>
              <li className="mt-5">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-full bg-teal-500 text-white">
                      <MdAppSettingsAlt size={25}/>
                    </div>
                  </div>
                  <div className="ml-4">
                      <h5 className="text-lg leading-6 text-slate-900 dark:text-white font-bold">
                          Déploiement
                      </h5>
                      <p className="mt-2 text-base leading-6 font-light text-slate-500 dark:text-slate-300">
                        Landing page avec ou sans copywrite, boutique en ligne, application mobile de contenus
                      </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div className="lg:flex justify-around">
            <SimplePricingCard
              link="https://form.jotform.com/213491665666567?prestaType=Appel%20%C3%A0%20l'acte"
              title="Appel à l'acte"
              price="59 €"
              btnText="Prendre RDV"
              mode='appel'
              features={["20 minutes gratuites", "1 heure d'échange", "Paiement à la fin de l'appel"]}
            />
            <SimplePricingCard
              link='https://form.jotform.com/213491665666567?prestaType=Accompagnement%20technologique'
              title="Forfait"
              price="Sur devis"
              btnText="Demander un devis"
              mode='jour ou mois'
              features={["Services sur mesure", "Avec ou sans engagement", "Entretien préalable gratuit"]}
            />
          </div>
        </div>
        <svg
          className="absolute -bottom-px overflow-hidden w-full"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className={`text-slate-200 fill-current`}
            points="2560 0 2560 100 0 100"
          ></polygon>
        </svg>
      </div>
    </MainLayout>
  );
};

export default TechServices;
