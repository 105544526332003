import React from "react";

import { FaLinkedinIn } from 'react-icons/fa';
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <footer className="relative bg-slate-200 pt-8 pb-6">
        <div className="container mx-auto px-4">
          <div className="flex flex-wrap text-center lg:text-left">
            <div className="w-full lg:w-6/12 px-4">
              <h4 className="text-3xl font-semibold text-slate-700">Rencontrons-nous !</h4>
              <h5 className="text-lg mt-0 mb-0 text-slate-600">
                Quoi de mieux que d'échanger autour d'un café ?
              </h5>
              <h6 className="text-sm mt-0 mb-2 text-slate-600 italic">
                Discutons ensemble de votre projet <strong>sans formalités, sans investissement et sans engagement</strong>.
              </h6>
              <div className="mt-6 lg:mb-0 mb-6 flex align-center justify-center lg:justify-start">
                <a
                  className="bg-white text-sky-600 shadow-lg font-normal h-10 w-10 flex items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2"
                  href="https://www.linkedin.com/company/hivewapp"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaLinkedinIn className="" />
                </a>
              </div>
            </div>
            <div className="w-full lg:w-6/12 px-4">
              <div className="flex flex-wrap items-top mb-6">
                <div className="w-full lg:w-4/12 px-4 ml-auto">
                  <span className="block uppercase text-orange-400 text-sm font-semibold mb-2">
                    Nous rejoindre
                  </span>
                  <ul className="list-unstyled">
                    <li>
                      <a
                        className="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                        href="mailto:contact@hivewapp.com?subject=Devenir partenaire"
                      >
                        Devenir partenaire
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-slate-600 hover:text-slate-800 font-semibold block pb-2 text-sm"
                        href="mailto:contact@hivewapp.com?subject=Candidature spontanée"
                      >
                        Postuler
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="w-full lg:w-4/12 px-4">
                  <span className="block uppercase text-orange-400 text-sm font-semibold mb-2">
                    Contactez-nous
                  </span>
                  <p className="text-slate-600 font-semibold block pb-2 text-sm">
                    &nbsp;
                    <br/>
                    {/*LIZINE
                    <br/>
                    30 rue André LARDY
                    <br/>
                    97438 Sainte Marie
                    <br/>
                    La Réunion, France
                    <br/>*/}
                    contact@hivewapp.com
                  </p>
                </div>
              </div>
            </div>
          </div>
          <hr className="my-6 border-slate-300" />
          <div className="flex flex-wrap items-center md:justify-between justify-center">
            <div className="w-full px-4 mx-auto text-center">
              <div className="text-sm text-slate-500 font-semibold py-1">
                © Tous droits réservés {new Date().getFullYear()} HIVEWAPP |{" "}
                <Link
                  to="/legal"
                  className="text-slate-500 hover:text-slate-800"
                >
                  Mentions légales
                </Link> |
                Theme inspired by{" "}
                <a
                  href="https://www.creative-tim.com?ref=nr-footer"
                  className="text-slate-500 hover:text-slate-800"
                  rel="nofollow noreferrer"
                  target='_blank'
                >
                  Creative Tim
                </a>
                .
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
