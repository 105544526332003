import React from "react";
import { Helmet } from "react-helmet";

import MainLayout from "../layouts/MainLayout";

const LegalPage = () => {
  return (
    <MainLayout>
      <Helmet>
        <meta property="og:url" content="https://hivewapp.com/legal" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hivewapp - Mentions légales" />
        <meta property="og:description" content="Mentions légales de la société Hivewapp" />
        <meta property="og:image" content="https://hivewapp.com/handshake.jpg" />
        <meta name="keywords" content="hivewapp, accompagnement, séminaires, developpement commercial,
        consulting, conseils, projet, supervision, spécifications, mvp, développement informatique, méthodologie, landing page, outils technologiques,
        lean startup, architecture techniques, startup studio, la réunion, 974" />
        <meta name="description" content="Apprenez à communiquer et à gérer votre équipe technique en découvrant les derniers outils technologiques" />
        <title>Hivewapp - Mentions légales</title>
      </Helmet>
      <main className="bg-slate-200 pt-32">
        <div className="container mx-auto bg-white shadow-xl rounded-lg z-10 py-16 px-12 ">
          <h1 className="font-semibold text-3xl text-slate-700">
            Mentions légales
          </h1>
          <h4 className="font-semibold text-slate-700 mt-2 text-2xl">
            Notre société
          </h4>
          <dl className="">
            <dt className="font-semibold float-left mr-2">Raison sociale :</dt>
            <dd className="">Hivewapp SAS</dd>
            <dt className="font-semibold float-left mr-2">Siège social :</dt>
            <dd className="">
              Cour de l'Usine - La Mare 97438 Sainte-Marie
            </dd>
            <dt className="font-semibold float-left mr-2">Adresse de courrier électronique : </dt>
            <dd className="">contact@hivewapp.com</dd>
            <dt className="font-semibold float-left mr-2">Capital social :</dt>
            <dd className="">1000 €</dd>
            <dt className="font-semibold float-left mr-2">Registre du commerce et des sociétés : </dt>
            <dd className="">912 218 336 R.C.S. Saint Denis de La Réunion</dd>
            <dt className="font-semibold float-left mr-2">Identification fiscale : </dt>
            <dd className="">FR34912218336</dd>
            <dt className="font-semibold float-left mr-2">Responsable de publication : </dt>
            <dd className="">Djothi Grondin</dd>
          </dl>
          <h4 className="font-semibold text-slate-700 mt-2 text-2xl">
            Hébergeur
          </h4>
          <dl className="">
            <dt className="font-semibold float-left mr-2">Dénomination sociale :</dt>
            <dd className="">Google Ireland Limited, société de droit irlandais immatriculée en Irlande (sous le numéro : 368047)</dd>
            <dt className="font-semibold float-left mr-2">Siège social :</dt>
            <dd className="">
              Gordon House, Barrow Street, Dublin 4, Irlande
            </dd>
            <dt className="font-semibold float-left mr-2">Téléphone : </dt>
            <dd className="">0805-540-801</dd>
          </dl>
          <h4 className="font-semibold text-slate-700 mt-2 text-2xl">
            Cookies
          </h4>
          <p>
            Un cookie est un outil informatique qui permet de tracer et d'analyser le comportement d'un usager sur internet (sa navigation sur internet, sur un site, dans une application, etc.).
            Les cookies soumis au consentement des internautes sont ceux ayant pour but de personnaliser les publicités et ceux liés aux réseaux sociaux.
            Ce site n'utilise pas de cookies.
            Pour vérifier la présence de cookie, entrez l'URL à tester sur https://www.cookieserve.com
          </p>
          <h4 id="rgpd" className="font-semibold text-slate-700 mt-2 text-2xl">
            Données personnelles
          </h4>
          <p>
            Dans le cadre de nos prestations de services, nous recueillons un ensemble de données décrites ci-après.
            Ces données ne sont ni échangés, ni cédés. Les données personnelles recueillies sont rendues accessibles uniquement aux personnes habilitées chez Hivewapp ou ses prestataires.
            La collecte de données est délégué au prestataire JotForm et sont stockées dans l'Union Européenne (https://www.jotform.com/gdpr-compliance/).
            La durée de conservation des données personnelles est variable et déterminée par la finalité et/ou les obligations légales.
          </p>
            <h6 className="font-semibold text-slate-700">Finalités poursuivies par la collecte</h6>
            <ul className="list-disc">
              <li>
                Gestion des contrats
                <br/>
                Données conservées pour une durée de 5 ans après la fin de la relation contractuelle
              </li>
              <li>
                Gestion de programmes de fidélité
                <br/>
                Données conservées pendant la durée de la relation contractuelle.
              </li>
              <li>
                Tenue de la comptabilité générale et des comptabilités auxiliaires étant rattachées
                <br/>
                Données conservées pour une durée de 5 ans après la fin de la relation contractuelle
              </li>
              <li>
                Établissement de statistiques financières
                <br/>
                Données conservées pour une durée de 3 ans à compter du dernier contact que les personnes auxquelles elles se rapportent ont eu avec Hivewapp
              </li>
              <li>
                Gestion des demandes de droit d’accès, de rectification et d’opposition
                <br/>
                Données conservées pour une durée de 3 ans
              </li>
              <li>
                Réalisation d’enquêtes de satisfaction et d’études clients
                <br/>
                Données conservées pendant la durée de la relation contractuelle.
              </li>
              <li>
                Gestion des réclamations, du service après-vente et des garanties
                <br/>
                Données conservées pour une durée de 5 ans après la fin de la relation contractuelle
              </li>
              <li>
                Réalisation d’actions de prospection commerciale et de marketing
                <br/>
                Données conservées pour une durée de 3 ans à compter du dernier contact que les personnes auxquelles elles se rapportent ont eu avec Hivewapp
              </li>
            </ul>
            <h6 className="font-semibold text-slate-700">Liste des données personnelles recueillies</h6>
            <ul className="list-disc">
              <li>
                Données liées à l'identité d'une personne : nom, prénon, N° de téléphone, email
              </li>
              <li>
                Données relatives aux moyens de paiements
              </li>
              <li>
                Données relatives à la transaction
              </li>
              <li>
                Données relatives aux suivis de la relation commerciale
              </li>
              <li>
                Données relatives aux règlements des factures
              </li>
              <li>
                Données nécessaires à la réalisation des actions de fidélisation, de prospection, d’étude, de sondage, de test produit et de promotion
              </li>
              <li>
                Données relatives à l’organisation et au traitement des jeux concours, de loteries et de toute opération promotionnelle
              </li>
              <li>
                Données relatives aux contributions des personnes qui déposent des avis sur des produits, services ou contenus, notamment leur pseudonyme 
              </li>
            </ul>
            <br/>
            <h6 className="font-semibold text-slate-700">Vos droits</h6>
            <p>
            <br/>- droit de retirer votre consentement ou de vous opposer au traitement de vos données
            <br/>- droit d’accès, de rectification et d’effacement des données qui vous concernent
            <br/>- droit à la limitation du traitement
            <br/>- droit à la portabilité pour vous permettre de recevoir, dans un format structuré et couramment utilisé, l’ensemble des données traitées par des moyens automatisés
            <br/>- droit de réclamation auprès de la CNIL directement sur le site de la CNIL (www.cnil.fr) ou par voie postale à : CNIL - 3 Place de Fontenoy - TSA 80715 - 75334 PARIS CEDEX 07.
            <br/> Pour exercer vos droits, adressez votre demande à contact@hivewapp.com
            </p>
          <h4 className="font-semibold text-slate-700 mt-2 text-2xl">
            Crédits
          </h4>
          <dl className="">
            <dt className="font-semibold float-left mr-2">Photos :</dt>
            <dd className="">https://pixabay.com/fr/</dd>
            <dt className="font-semibold float-left mr-2">Illustrations :</dt>
            <dd className="">
              https://www.vecteezy.com/free-vector/computer
            </dd>
          </dl>
        </div>
      </main>
    </MainLayout>
  );
};

export default LegalPage;
